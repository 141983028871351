.faqItem {
  margin-bottom: 1rem;
  border: 1px solid #d2c0b2;
  border-radius: 8px;
  overflow: hidden;
  width: 500px;
  margin: 15px auto;
  direction: rtl;
  box-shadow: 0 2px 4px rgba(210, 192, 178, 0.2);
  text-decoration: none;
}

.questionButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #f8f4f1;
  border: none;
  text-decoration: none;
  color: #5a4a3f;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.questionButton:hover {
  background-color: #f1e9e4;
}

.questionText {
  font-family: 'AssistantR', sans-serif;
  font-size: 1.2rem;
  text-align: center;
  color: #5a4a3f;
  direction: rtl;
  flex-grow: 1;
}

.iconWrapper {
  width: 24px;
  flex-shrink: 0;
  color: #b3967f;
  text-decoration: none;
}

.icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.answerContainer {
  overflow: hidden;
  transition: height 0.3s ease;
  background-color: #fdfbf9;
  margin: auto;
  direction: rtl;
}

.answerText {
  font-family: 'AssistantR', sans-serif;
  font-size: 1.05rem;
  padding: 1rem;
  color: #5a4a3f;
  justify-content: center;
  text-align: center;
  margin: auto;
}

/* Accent for links or important text within answers */
.answerText a, .answerText strong {
  color: #b3967f;
}

/* Responsive styles remain unchanged */
@media (max-width: 350px) {
  .faqItem {
    width: 280px;
  }
  .questionText {
    font-size: 1rem;
  }
  .answerText {
    font-size: 0.9rem; 
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .faqItem {
    width: 320px;
  }
  .questionText {
    font-size: 1.05rem;
  }
  .answerText {
    font-size: 0.95rem; 
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .faqItem {
    width: 400px;
  }
  .questionText {
    font-size: 1.1rem;
  }
  .answerText {
    font-size: 1rem; 
  }
}